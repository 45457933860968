import { api } from '@/providers/api';
import {loadingController, alertController} from "@ionic/vue";
import useAuth from '@/providers/auth';

export default function(){
    const { logout } = useAuth();

    async function post(endpoint: string, params: any, loadingMessage = "Cargando...", showError = true){
        const loading = await loadingController.create({
            message: loadingMessage,
            spinner: "crescent"
        });
        try {
            loading.present();
            const {data} = await api.post(endpoint, params);
            loading.dismiss();
            if(data.error){
                if(showError){
                    const alert = await alertController.create({
                        header: 'Error',
                        message: data.message,
                        cssClass: 'error-alert'
                    });
                    await alert.present();
                }
                if(data.reauth){
                    logout();
                }
            }
            return data;
        } catch (e) {
            loading.dismiss();
            const alert = await alertController.create({
                header: 'Error',
                message: 'Ocurrió un error al conectarse con el servidor',
                cssClass: 'error-alert'
            });
            await alert.present();
            return null;
        }
    }

    return {
        post
    }
}