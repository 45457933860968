import { reactive, toRefs } from "vue";
import { api, storage, loadAuthorizationToken } from "./api";

interface State {
  user?: any;
  init: boolean;
  token: string | undefined;
}

const state = reactive<State>({
  user: null,
  init: false,
  token: undefined,
});

export default function() {
  state.token = storage.get("token");

  function login(token: string, user: any) {
    storage.set("token", token);
    console.log(token);
    state.token = token;
    state.user = user;
    loadAuthorizationToken();
  }

  function logout() {
    storage.clear();
    state.token = undefined;
    state.user = null;
  }

  function authCheck() {
    return new Promise((resolve) => {
      if (state.token === undefined) {
        state.init = true;
        resolve(false);
      } else {
        !state.init &&
          api
            .post("users/validate", { token: state.token })
            .then(({ data }) => {
              if (data.error) {
                logout();
                //Swal.fire("Error", data.message, "error");
              } else {
                state.user = data.user;
                loadAuthorizationToken();
              }
              state.init = true;
              resolve(true);
            });
      }
    });
  }

  return {
    ...toRefs(state),
    login,
    logout,
    authCheck,
  };
}
