import initials from './initials';
import iniciales from './iniciales';
import fecha from './fecha';
import datetime from './datetime';
import whatsapp from "./whatsapp";
import folio from "./folio";
import capitalize from "./capitalize";
import mUnit from "./m_unit";
import timeFrom from "./timefrom";

export default {
  initials,
  iniciales,
  fecha,
  datetime,
  whatsapp,
  folio,
  capitalize,
  mUnit,
  timeFrom
};