export default function(value: string) {
  if (!value) return "";
  return value == "pieza"
    ? "Pza"
    : value == "kilos"
    ? "k"
    : value == "litros"
    ? "lt"
    : value;
}
