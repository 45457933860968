import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import filters from "@/filters/filters";

import { IonicVue } from "@ionic/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import '@/assets/css/global.css';

import useAuth from "@/providers/auth";

import useLottie from "./use/useLottie";

//useLottie().showSplash();


const app = createApp(App)
  .use(IonicVue)
  
//Filters
app.config.globalProperties.$filters = filters;

const { authCheck } = useAuth();

authCheck().finally(() => {
  setTimeout(() => {
    //useLottie().hideSplash();
  }, 2000);
  app.use(router);
});

router.isReady().then(() => {
  app.mount("#app");
});
