
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
  IonInput,
  IonButton,
} from "@ionic/vue";
import { defineComponent, reactive, toRefs } from "vue";
import useAuth from "@/providers/auth";
import { useRouter } from "vue-router";
import useApi from "@/use/useApi";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonImg,
    IonInput,
    IonButton,
  },
  setup() {
    const router = useRouter();
    const { login } = useAuth();
    const { post } = useApi();

    //Data
    const state = reactive({
      username: "",
      pass: null,
    });

    //Methods
    async function send() {
      const data = await post("users/login", state, "");
      if (data !== null) {
        if (!data.error) {
          login(data.token, data.user);
          router.push({ path: "/" + data.user.role + "/orders", replace: true });
        }
      }
    }

    return {
      state,
      send,
    };
  },
});
