<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card id="container">
        <ion-card-header>
          <ion-img src="/assets/images/plastik.png"></ion-img>
        </ion-card-header>
        <ion-card-content>
          <form @submit.prevent="send" class="ion-padding">
            <ion-input
              class="ion-margin-vertical ion-padding-horizontal ion-text-left ion-input"
              placeholder="Usuario o Correo Electrónico"
              v-model="state.username"
            ></ion-input>
            <ion-input
              class="ion-margin-vertical ion-padding-horizontal ion-text-left ion-input"
              placeholder="Contraseña"
              type="password"
              v-model="state.pass"
            ></ion-input>
            <ion-button
              type="submit"
              class="ion-margin-bottom"
              expand="block"
              color="primary"
            >
              Ingresar
            </ion-button>
          </form>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonImg,
  IonInput,
  IonButton,
} from "@ionic/vue";
import { defineComponent, reactive, toRefs } from "vue";
import useAuth from "@/providers/auth";
import { useRouter } from "vue-router";
import useApi from "@/use/useApi";

export default defineComponent({
  name: "Login",
  components: {
    IonContent,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonImg,
    IonInput,
    IonButton,
  },
  setup() {
    const router = useRouter();
    const { login } = useAuth();
    const { post } = useApi();

    //Data
    const state = reactive({
      username: "",
      pass: null,
    });

    //Methods
    async function send() {
      const data = await post("users/login", state, "");
      if (data !== null) {
        if (!data.error) {
          login(data.token, data.user);
          router.push({ path: "/" + data.user.role + "/orders", replace: true });
        }
      }
    }

    return {
      state,
      send,
    };
  },
});
</script>

<style scoped lang="scss">
#container {
  text-align: center;
  position: absolute;
  width: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-53%, -50%);
  background-color: var(--ion-color-light);
}
ion-content::part(background) {
  background: no-repeat center url("/assets/images/login.png");
  background-size: cover;
}
ion-img::part(image) {
  height: 80px;
}
ion-input {
  --background: #fff;
}
.ion-input {
  border-radius: 7px;
  border: 1px solid #e3e3e3;
}
</style>
