import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw, RouterView } from "vue-router";
import Login from "../views/login.vue";
import useAuth from "@/providers/auth";

const { user, token } = useAuth();
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/rotomolde",
    name: "Sistema R",
    component: () => import("@/views/system/rotomolde/tabs.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "orders",
        name: "Orders R",
        component: () => import("@/views/system/rotomolde/orders.vue"),
      },
      {
        path: "orders/:id",
        name: "Order R",
        component: () => import("@/views/system/rotomolde/detail.vue"),
      },
      {
        path: "orders/:id/:product",
        name: "Order R Product",
        component: () => import("@/views/system/rotomolde/detail/register.vue"),
      },
      {
        path: "orders/:id/edit/:product",
        name: "Order R E. Product",
        component: () => import("@/views/system/rotomolde/detail/edit.vue"),
      },
      {
        path: "logout",
        name: "LogOut R",
        component: () => import("@/views/logout.vue"),
      },
    ],
  },
  {
    path: "/armador",
    name: "Sistema A",
    component: () => import("@/views/system/armador/tabs.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "orders",
        name: "Orders A",
        component: () => import("@/views/system/armador/orders.vue"),
      },
      {
        path: "orders/:id",
        name: "Order A",
        component: () => import("@/views/system/armador/detail.vue"),
      },
      {
        path: "orders/:id/:product",
        name: "Order A Product",
        component: () => import("@/views/system/armador/detail/edit.vue"),
      },
      {
        path: "logout",
        name: "LogOut A",
        component: () => import("@/views/logout.vue"),
      },
    ],
  },
  {
    path: "/calidad",
    name: "Sistema C",
    component: () => import("@/views/system/calidad/tabs.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "orders",
        name: "Orders C",
        component: () => import("@/views/system/calidad/orders.vue"),
      },
      {
        path: "product/:id",
        name: "Product C",
        component: () => import("@/views/system/calidad/detail.vue"),
      },
      {
        path: "logout",
        name: "LogOut A",
        component: () => import("@/views/logout.vue"),
      },
    ],
  },
  {
    path: "/coordinador_lapt",
    name: "Sistema A.",
    component: () => import("@/views/system/almacen/tabs.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "orders",
        name: "Orders A.",
        component: () => import("@/views/system/almacen/orders.vue"),
      },
      {
        path: "logout",
        name: "LogOut A.",
        component: () => import("@/views/logout.vue"),
      },
    ],
  },
  {
    path: "/system",
    name: "Sistema",
    component: () => import("@/views/system/armador/tabs.vue"),
    meta: {
      auth: true,
    },
    children: [
      {
        path: "logout",
        name: "LogOut",
        component: () => import("@/views/logout.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("@/views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if ((!user.value || token.value === undefined) && to.name != "Login") {
    next({ name: "Login", replace: true });
  } else if (to.name === "Login" && user.value) {
    let role = user.value.role;
    if (user.value.role == "developer") {
      role = "calidad";
    }
    next({ path: "/" + role + "/orders", replace: true });
  } else {
    next();
  }
});

export default router;
