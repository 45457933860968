import axios from 'axios';
import {API_URL, APP_PREFIX} from "./config";
import LightStorage from "light-storage";

export const storage = new LightStorage(APP_PREFIX);

//Base URL
axios.defaults.baseURL = API_URL;
//Tipo de Respuesta
axios.defaults.responseType = "json";


export function loadAuthorizationToken(){
    if(storage.get('token')){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + storage.get('token');
    } else {
        axios.defaults.headers.common['Authorization'] = '';
    }
}

//Leer token inicial
loadAuthorizationToken();

export const api = axios;
